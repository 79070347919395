import classNames from 'classnames';

export const navigationBar = classNames(
  'fixed z-40 inset-x-0 top-0 flex justify-between items-center gap-12 border-b border-[color:var(--color-divider)] transition bg-[color:var(--color-midnight-dark)] backdrop-blur-sm',
);

export const navigationSpacing = classNames('px-4');

export const navigationDimensions = classNames('h-16');

export const navigationResponsive = classNames('sm:px-6 lg:left-72 lg:z-50 lg:px-8 xl:left-80');

export const navigationDivider = classNames('absolute inset-x-0 h-px transition top-full');
