import React from 'react';

interface Props {
  children: any;
}

const Aside = ({ children, ...rest }: Props) => (
  <div
    {...rest}
    className="my-6 flex items-center gap-2.5 rounded-xl border border-[var(--color-warning)] bg-[var(--color-warning-aside)] p-4 leading-6 [--tw-prose-links-hover:1C61C4] [--tw-prose-links:white]"
  >
    <svg
      className="flex-none w-6 h-6 mr-2 fill-[var(--color-warning)]"
      viewBox="0 0 20 20"
      fill="none"
      width="24"
      height="24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4841 2.49488C9.1571 1.32788 10.8411 1.32788 11.5141 2.49488L17.7941 13.3699C18.4671 14.5369 17.6241 15.9949 16.2781 15.9949H3.7191C2.3721 15.9949 1.5301 14.5369 2.2041 13.3699L8.4841 2.49488ZM9.9991 4.99988C10.198 4.99988 10.3888 5.0789 10.5294 5.21955C10.6701 5.3602 10.7491 5.55097 10.7491 5.74988V9.24988C10.7491 9.44879 10.6701 9.63956 10.5294 9.78021C10.3888 9.92086 10.198 9.99988 9.9991 9.99988C9.80019 9.99988 9.60942 9.92086 9.46877 9.78021C9.32812 9.63956 9.2491 9.44879 9.2491 9.24988V5.74988C9.2491 5.55097 9.32812 5.3602 9.46877 5.21955C9.60942 5.0789 9.80019 4.99988 9.9991 4.99988ZM9.9991 13.9999C10.2643 13.9999 10.5187 13.8945 10.7062 13.707C10.8937 13.5194 10.9991 13.2651 10.9991 12.9999C10.9991 12.7347 10.8937 12.4803 10.7062 12.2928C10.5187 12.1052 10.2643 11.9999 9.9991 11.9999C9.73388 11.9999 9.47953 12.1052 9.29199 12.2928C9.10446 12.4803 8.9991 12.7347 8.9991 12.9999C8.9991 13.2651 9.10446 13.5194 9.29199 13.707C9.47953 13.8945 9.73388 13.9999 9.9991 13.9999Z"
        fill="#E8C11E"
      />
    </svg>

    <div className="[&amp;>:first-child]:mt-0 [&amp;>:last-child]:mb-0">
      <p>{children}</p>
    </div>
  </div>
);

export default Aside;
