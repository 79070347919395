import React from 'react';

export const CloseSVG = () => (
  <>
    <svg
      className="close-svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 16.32L12 12.945L15.375 16.32L16.32 15.375L12.945 12L16.32 8.625L15.375 7.68L12 11.055L8.625 7.68L7.68 8.625L11.055 12L7.68 15.375L8.625 16.32ZM12 21C10.77 21 9.6075 20.7638 8.5125 20.2912C7.4175 19.8187 6.46125 19.1737 5.64375 18.3562C4.82625 17.5387 4.18125 16.5825 3.70875 15.4875C3.23625 14.3925 3 13.23 3 12C3 10.755 3.23625 9.585 3.70875 8.49C4.18125 7.395 4.82625 6.4425 5.64375 5.6325C6.46125 4.8225 7.4175 4.18125 8.5125 3.70875C9.6075 3.23625 10.77 3 12 3C13.245 3 14.415 3.23625 15.51 3.70875C16.605 4.18125 17.5575 4.8225 18.3675 5.6325C19.1775 6.4425 19.8187 7.395 20.2912 8.49C20.7638 9.585 21 10.755 21 12C21 13.23 20.7638 14.3925 20.2912 15.4875C19.8187 16.5825 19.1775 17.5387 18.3675 18.3562C17.5575 19.1737 16.605 19.8187 15.51 20.2912C14.415 20.7638 13.245 21 12 21ZM12 19.65C14.13 19.65 15.9375 18.9038 17.4225 17.4113C18.9075 15.9188 19.65 14.115 19.65 12C19.65 9.87 18.9075 8.0625 17.4225 6.5775C15.9375 5.0925 14.13 4.35 12 4.35C9.885 4.35 8.08125 5.0925 6.58875 6.5775C5.09625 8.0625 4.35 9.87 4.35 12C4.35 14.115 5.09625 15.9188 6.58875 17.4113C8.08125 18.9038 9.885 19.65 12 19.65Z"
        fill="#fff"
      />
    </svg>
  </>
);
