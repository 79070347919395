import React from 'react';

export const MagnifierSVG = () => (
  <>
    <svg width="22" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7752 20.9941L13.2638 14.4886C12.7753 14.9033 12.204 15.2269 11.55 15.4592C10.896 15.6914 10.197 15.8076 9.45304 15.8076C7.65 15.8076 6.12373 15.1822 4.87424 13.9314C3.62475 12.6805 3 11.1714 3 9.40379C3 7.63623 3.62541 6.12703 4.87624 4.87621C6.12704 3.6254 7.64033 3 9.4161 3C11.1919 3 12.7011 3.6254 13.9437 4.87621C15.1863 6.12703 15.8076 7.63746 15.8076 9.40748C15.8076 10.1188 15.6956 10.7998 15.4717 11.4505C15.2478 12.1013 14.9119 12.712 14.464 13.2826L21 19.7694L19.7752 20.9941ZM9.43051 14.13C10.7393 14.13 11.8498 13.6683 12.7619 12.745C13.6739 11.8217 14.13 10.708 14.13 9.40379C14.13 8.09962 13.6736 6.98588 12.761 6.06259C11.8484 5.13928 10.7382 4.67762 9.43051 4.67762C8.10913 4.67762 6.98663 5.13928 6.06303 6.06259C5.13942 6.98588 4.67762 8.09962 4.67762 9.40379C4.67762 10.708 5.13914 11.8217 6.06217 12.745C6.98518 13.6683 8.10796 14.13 9.43051 14.13Z"
        fill="#3B4654"
      />
    </svg>
  </>
);
