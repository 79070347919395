import React from 'react';

export const GridPatternSVG = () => (
  <>
    <svg
      viewBox="0 0 1113 440"
      aria-hidden="true"
      className="absolute left-1/2 top-0 ml-[-19rem] w-[69.5625rem] fill-white blur-[26px] dark:hidden"
    >
      <path d="M.016 439.5s-9.5-300 434-300S882.516 20 882.516 20V0h230.004v439.5H.016Z" />
    </svg>
  </>
);
