import React, { useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  header,
  headerContainer,
  headerContainerSpacing,
  sideBarBorder,
  sideBarListBorder,
  sideBarTitle,
} from './Sidebar.styles';
import { backgroundDark } from '../../assets/styles/global';
import Logo from '../../assets/images/Logo-white.webp';
import { NavBar } from '../NavBar/NavBar';
import { ListItem } from './ListItem';
import { CloseSVG } from '../../assets/svg/close';
import DocumentationRoutes from '../../routes';
import { RouteCorrector } from '../../helpers/routeCorrector';
import { selectDisplayMode, setDisplayMode } from '../../features/app/appSlice';
import { capitaliseFirstLetter } from '../../helpers/capitaliseFirstLetter';

export const Sidebar = () => {
  const displayMode = useAppSelector(selectDisplayMode);
  const dispatch = useAppDispatch();

  const ToggleMenu = (display: boolean) => {
    dispatch(setDisplayMode(display));
  };

  const closeMenu = () => {
    dispatch(setDisplayMode(false));
  };

  useLayoutEffect(() => {
    function closeMobileMenu() {
      if (window.innerWidth >= 1024) {
        dispatch(setDisplayMode(false));
      }
    }
    window.addEventListener('resize', closeMobileMenu);
    closeMobileMenu();
    return () => window.removeEventListener('resize', closeMobileMenu);
  }, [window.innerWidth]);

  return (
    <header className={header}>
      <div
        className={`${backgroundDark} ${sideBarBorder} ${headerContainer} ${headerContainerSpacing}`}
      >
        <div onClick={closeMenu} className={displayMode ? 'fixed right-3 top-3 z-50' : 'hidden'}>
          <CloseSVG />
        </div>
        {/* Logo */}
        <div className="hidden lg:flex">
          <NavLink aria-label="Home" to="/" className="flex items-center justify-center">
            <img src={Logo} alt="Logo" className="w-6" />
            <h2 className="ml-3.5 font-bold">URBAN SPORTS CLUB</h2>
          </NavLink>
        </div>
        {/* Top Nav Bar */}
        <NavBar displayMode={displayMode} changeDisplayMode={ToggleMenu} />
        {/* Sidebar */}
        <nav
          className={
            displayMode
              ? 'sidebar lg:mt-10 lg:block z-40 overflow-scroll'
              : 'hidden sidebar lg:mt-10 lg:block'
          }
        >
          <ul role="list">
            <NavLink
              aria-label="Home"
              to="/"
              className="flex items-center justify-start mb-10 lg:hidden"
            >
              <img src={Logo} alt="Logo" className="w-5" />
              <h2 className="ml-3 font-bold">URBAN SPORTS CLUB</h2>
            </NavLink>
            <li className="relative mt-6 mb-8 md:mt-0">
              <h2 className={`${sideBarTitle}`}>Main</h2>
              <div className="relative pl-2 mt-3">
                <ul role="list" className={`sidebarList ${sideBarListBorder}`}>
                  <ListItem title="Home" address="/" hasChildren={false} />
                </ul>
              </div>
            </li>
            {DocumentationRoutes.map((group, id) => (
              <li key={id} className="relative mt-6 mb-8 md:mt-0">
                <h2 className={`${sideBarTitle}`}>{capitaliseFirstLetter(group.title)}</h2>
                <div className="relative pl-2 mt-3">
                  <ul role="list" className={`sidebarList ${sideBarListBorder}`}>
                    {group.content?.map((route, id) => (
                      <ListItem
                        key={id}
                        title={route.title}
                        address={RouteCorrector(`/${group.title}/${route.title}`)}
                        hasChildren={route.children ? true : false}
                        childLinks={route.children && route.children}
                      />
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};
