import classNames from 'classnames';

export const contactUscard = classNames(
  'absolute inset-0 rounded-2xl ring-1 ring-inset ring-zinc-900/7.5 group-hover:ring-black/20',
);

export const contactUsContainer = classNames(
  'grid grid-cols-1 gap-8 pt-10 mt-4 border-t not-prose border-[color:var(--color-divider)] sm:grid-cols-1 xl:grid-cols-3',
);

export const contactUscardContainer = classNames(
  'group relative flex rounded-2xl bg-[var(--color-midnight-dark)] transition-shadow hover:shadow-md hover:shadow-zinc-900/5bg-white/2.5 hover:shadow-black/5',
);

export const contactUsCardIcon = classNames(
  'flex h-7 w-7 items-center justify-center rounded-full',
);

export const contactUsHeading = classNames(
  'mt-4 text-base font-semibold leading-7 text-zinc-900text-white',
);

export const contactUsParagraph = classNames('my-2 text-[0.92rem] text-zinc-400');
