import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { BookOpenIcon, QueueListIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/solid';
import DashboardLayout from '../../layout/DashboardLayout';
import {
  notFoundCardParagraph,
  notFoundCardTitle,
  notFoundHint,
  notFoundIcon,
  notFoundIConContainer,
  notFoundList,
  notFoundNumber,
} from '../../assets/styles/global';
import { GlobalT } from '../../assets/content/text/text.en';
import { Link } from 'react-router-dom';

const links = [
  {
    name: 'Documentation',
    href: '/documentation/introduction',
    description: 'Read our documentation.',
    icon: BookOpenIcon,
  },
  {
    name: 'Endpoints',
    href: '/endpoint/trainers',
    description: 'Read about our endpoints.',
    icon: QueueListIcon,
  },
  {
    name: 'Contact us',
    href: 'mailto: connect@urbansportsclub.com',
    description: "Can't find what you're looking for? click here to contact us!",
    icon: ChatBubbleLeftIcon,
  },
];

const NotFound = () => (
  <DashboardLayout>
    <div className="max-w-lg mx-auto text-center">
      <p className={notFoundNumber}>404</p>
      <span className="text-2xl font-semibold leading-8 text-[var(--color-blau-neue)] px-5">
        {GlobalT.pageNotExist}
      </span>
    </div>
    <div className="max-w-lg mx-auto mt-12 font-semibold">
      <p className={notFoundHint}>{GlobalT.mightLookFor}</p>
      <ul role="list" className={notFoundList}>
        {links.map((link, linkIdx) => (
          <li key={linkIdx} className="relative flex py-6 my-2 gap-x-6">
            <div className={notFoundIConContainer}>
              <link.icon className={notFoundIcon} aria-hidden="true" />
            </div>
            <div className="flex-auto">
              <h3 className={notFoundCardTitle}>
                <a href={link.href}>
                  <span className="absolute inset-0" aria-hidden="true" />
                  {link.name}
                </a>
              </h3>
              <p className={notFoundCardParagraph}>{link.description}</p>
            </div>
            <div className="self-center flex-none">
              <ChevronRightIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </div>
          </li>
        ))}
      </ul>
      <div className="flex justify-center mt-10">
        <Link to="/" className="text-base font-semibold leading-6 text-[var(--color-blau-neue)]">
          {/* <span aria-hidden="true">&larr;</span> */}
          Back to home
        </Link>
      </div>
    </div>
  </DashboardLayout>
);

export default NotFound;
