import classNames from 'classnames';

export const header = classNames(
  'contents lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex',
);

export const headerContainer = classNames(
  'contents lg:pointer-events-auto lg:block lg:w-72 lg:overflow-y-auto lg:border-r xl:w-80',
);

export const headerContainerSpacing = classNames('lg:px-6 lg:pb-8 lg:pt-4');

export const sideBarBorder = classNames('lg:border-r lg:border-[color:var(--color-divider)]');

export const sideBarTitle = classNames('mb-4 text-sm font-semibold text-white-usc');

export const sideBarListBorder = classNames('border-l border-[color:var(--color-divider)]');

export const sideBarLink = classNames(
  'flex justify-between gap-2 py-1 pl-4 pr-3 text-base transition hover:text-white',
);

export const sideBarChildLink = classNames(
  'flex justify-between gap-2 py-1 pr-3 text-base transition pl-7 hover:text-white',
);

export const sideBarListItem = classNames('relative mb-3');

export const sideBarListItemActive = classNames(
  'relative mb-3 rounded-md bg-[color:var(--color-midnight-light-menu)]',
);
