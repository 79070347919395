import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

export interface AppState {
  displayMode: boolean;
}

const initialState: AppState = {
  displayMode: false,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDisplayMode: (state, { payload: displayMode }: PayloadAction<boolean>) => {
      state.displayMode = displayMode;
    },
    reset: () => initialState,
  },
});

export const { setDisplayMode, reset } = slice.actions;

export default slice.reducer;

export const selectDisplayMode = (state: RootState) => state.app.displayMode;
