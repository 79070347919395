import React, { ReactNode } from 'react';
import { Sidebar } from '../components/Sidebar/Sidebar';

interface Props {
  children?: ReactNode;
}

const DashboardLayout = ({ children }: Props) => (
  <>
    <div className="lg:ml-72 xl:ml-80">
      <Sidebar />
      <div className="relative px-4 pt-14 sm:px-6 lg:px-8">
        <main className="py-16 main-section">{children}</main>
      </div>
    </div>

    <main className="px-6 py-16 mx-2 sm:px-6 main-section-mobile">{children}</main>
  </>
);

export default DashboardLayout;
