import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark as CodeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface Props {
  className: string;
  children: string | string[];
}

const CodeBlock = ({ className, children }: Props) => {
  let lang = 'text';
  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '');
  }
  return (
    <SyntaxHighlighter language={lang} style={CodeStyle}>
      {children}
    </SyntaxHighlighter>
  );
};

export default CodeBlock;
