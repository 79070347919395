import React from 'react';
import { GridPattern } from '../../components/Design/GridPattern';
import DashboardLayout from '../../layout/DashboardLayout';
import {
  getStartedButton,
  gridPattern,
  mainBackgroundContainer,
  mainBackgroundGradient,
  mainPageH2,
} from '../../assets/styles/global';
import { GridPatternSVG } from '../../assets/svg/gridPattern';
import { RightArrowSVG } from '../../assets/svg/rightArrow';
import { GlobalT } from '../../assets/content/text/text.en';
import { Link } from 'react-router-dom';
import { Suggestions } from '../../components/Sections/Suggestions';
import { ContactUs } from '../../components/Sections/ContactUs';

const Main = () => (
  <DashboardLayout>
    <article className="px-5 prose prose-invert max-w-[54rem]">
      <div className={mainBackgroundContainer}>
        <div className={mainBackgroundGradient}>
          <div className={gridPattern}>
            <GridPattern
              width={72}
              height={56}
              x="-12"
              y="4"
              squares={[
                [4, 3],
                [2, 1],
                [7, 3],
                [10, 6],
              ]}
            />
          </div>
          <GridPatternSVG />
        </div>
      </div>
      <h1 className="text-3xl text-[color:var(--color-blue-secondary)]">
        {GlobalT.WelcomeToUrbanSports}
      </h1>
      <p className="text-base lead">{GlobalT.WelcomeMainParagraph}</p>
      <p className="text-base lead">{GlobalT.WelcomeParagraphSecondary}</p>
      <div className="flex gap-3 mt-6 mb-16 not-prose">
        <Link className={getStartedButton} to="documentation/introduction">
          {GlobalT.GetStarted}
          <RightArrowSVG />
        </Link>
      </div>
      <div className="my-16 xl:max-w-none">
        <h2 id="navigate" className={mainPageH2}>
          {GlobalT.MightLookFor}
        </h2>
        <Suggestions />
      </div>

      <div className="my-16 xl:max-w-none">
        <h2 id="guides" className={mainPageH2}>
          {GlobalT.NeedHelp}
        </h2>
        <ContactUs />
      </div>
    </article>
  </DashboardLayout>
);

export default Main;
