import React, { useId } from 'react';

interface GridProps {
  width: number;
  height: number;
  x: string;
  y: string;
  squares: number[][];
}

export function GridPattern({ width, height, x, y, squares, ...props }: GridProps) {
  let patternId = useId();

  return (
    <svg
      data-testid="grid-pattern"
      className="absolute inset-x-0 inset-y-[-50%] h-[200%] w-full skew-y-[-18deg] fill-black/40 mix-blend-overlay fill-white/2.5 stroke-white/5"
      aria-hidden="true"
      {...props}
    >
      <defs>
        <pattern
          id={patternId}
          width={width}
          height={height}
          patternUnits="userSpaceOnUse"
          x={x}
          y={y}
        >
          <path d={`M.5 ${height}V.5H${width}`} fill="none" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" strokeWidth={0} fill={`url(#${patternId})`} />
      {squares && (
        <svg x={x} y={y} className="overflow-visible">
          {squares.map(([x, y]) => (
            <rect
              strokeWidth="0"
              key={`${x}-${y}`}
              width={width + 1}
              height={height + 1}
              x={x * width}
              y={y * height}
            />
          ))}
        </svg>
      )}
    </svg>
  );
}
