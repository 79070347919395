import React from 'react';
import { Link } from 'react-router-dom';
import { GlobalT } from '../../assets/content/text/text.en';
import { LetterSVG } from '../../assets/svg/letter';
import { ResourcesSVG } from '../../assets/svg/resources';
import {
  contactUscard,
  contactUscardContainer,
  contactUsCardIcon,
  contactUsContainer,
  contactUsHeading,
  contactUsParagraph,
} from './ContactUs.styles';

export const ContactUs = () => (
  <div className={contactUsContainer}>
    <div className={contactUscardContainer}>
      <div className={contactUscard} />
      <div className="relative px-5 pt-8 pb-8 rounded-xl">
        <div className={contactUsCardIcon}>
          <LetterSVG />
        </div>
        <h3 className={contactUsHeading}>
          <Link to="mailto: connect@urbansportsclub.com">
            <span className="absolute inset-0 rounded-2xl" />
            {GlobalT.ContactUs}
          </Link>
        </h3>
        <p className={contactUsParagraph}>{GlobalT.NeedTechnicalSupport}</p>
        <p className={contactUsParagraph}>{GlobalT.HaveQuestions}</p>
        <p className={contactUsParagraph}>{GlobalT.NeedAssistance}</p>
        <p className="my-2 text-[0.92rem] text-[var(--color-blau-neue)]">
          {GlobalT.ClickToContact}
        </p>
      </div>
    </div>

    <div className={contactUscardContainer}>
      <div className={contactUscard} />
      <div className="relative px-5 pt-8 pb-8 rounded-xl">
        <div className={contactUsCardIcon}>
          <ResourcesSVG />
        </div>
        <h3 className={contactUsHeading}>
          <span className="absolute inset-0 rounded-2xl" />
          Links
        </h3>
        <a
          href="https://urbansportsclub.com/"
          target="_blank"
          className="my-2 text-[0.92rem] text-zinc-400 block relative"
        >
          {GlobalT.UrbanSportsClub}
        </a>
        <a
          href="https://www.fitogram.pro/"
          target="_blank"
          className="my-2 text-[0.92rem] text-zinc-400 block relative"
        >
          {GlobalT.Fitogram}
        </a>
        <a
          href="https://one.fit/"
          target="_blank"
          className="my-2 text-[0.92rem] text-zinc-400 block relative"
        >
          {GlobalT.OneFit}
        </a>
      </div>
    </div>
  </div>
);
