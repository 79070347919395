import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from '../pages/main/404';
import Documentation from '../pages/main/Documentation';
import Main from '../pages/main/Main';

export default () => (
  <Router>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route>
        <Route path="/:docGroup/:docId" element={<Documentation />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);
