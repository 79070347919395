import classNames from 'classnames';

export const FormContainer = classNames(
  'flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0',
);

export const Card = classNames(
  'w-full rounded-md shadow bg-[color:var(--color-midnight-dark)] md:mt-0 sm:max-w-md xl:p-0 border border-solid border-[color:var(--color-divider)]',
);

export const CardAlignment = classNames('p-6 space-y-4 md:space-y-6 sm:p-8');

export const ParagraphPrimary = classNames('font-normal text-white-usc text-base');

export const LinkPrimary = classNames(
  'text-[color:var(--color-blau-neue)] underline cursor-pointer',
);

export const HeadingMain = classNames(
  'text-xl font-bold leading-tight tracking-tight text-white-usc md:text-2xl',
);

export const HeadingMedium = classNames(
  'pt-3 font-bold leading-tight tracking-tight text-l text-white-usc md:text-xl',
);

export const ButtonPrimarySmall = classNames(
  'font-semibold px-3 py-2 text-white-usc bg-[color:var(--color-blau-neue)] rounded-md',
);

export const defaultCheckBox = classNames(
  'w-4 h-4 border-2 border-[color:var(--color-blau-neue)] rounded bg-transparent checked:ring-offset-transparent checked:ring-transparent checked:ring-offset-0',
);

export const defaultButton = classNames(
  'w-full text-white-usc bg-[color:var(--color-blau-neue)] hover:bg-[color:var(--color-blue-hover)] font-medium rounded-md text-base px-5 py-2.5 text-center',
);

export const redirectLink = classNames(
  'font-semibold ml-3 text-[color:var(--color-blau-neue)] underline cursor-pointer',
);

export const backgroundDark = classNames('bg-[color:var(--color-midnight-dark)]');

export const backgroundDividerColor = classNames('bg-[color:var(--color-divider)]');

export const borderDivider = classNames('border border-[color:var(--color-divider)]');

export const normalBorderBottom = classNames('border-b border-[color:var(--color-divider)]');

export const SearchBar = classNames(
  'hidden invisible items-center gap-2 rounded-full text-base text-zinc-500 ring-1 ring-zinc-900/10 transition hover:ring-zinc-900/20 dark:bg-white/5 lg:flex focus:[&amp;:not(:focus-visible)]:outline-none',
);

export const SearchBarContainer = classNames('hidden lg:block lg:max-w-md lg:flex-auto');

export const SearchBarDimensions = classNames('h-10 w-full');

export const SearchBarSpacing = classNames('pl-2 pr-3');

export const SearchBarInput = classNames(
  'items-center hidden w-10/12 h-10 gap-2 pl-2 pr-3 text-base transition bg-transparent border-none rounded-full outline-none ring-transparent lg:flex',
);

export const HamburgerMenuContainer = classNames('flex items-center gap-5 lg:hidden');

export const HamburgerMenu = classNames(
  'flex items-center justify-center w-6 h-6 transition rounded-md',
);

export const HamburgerMenuIcon = classNames(
  'w-3.5 stroke-white hover:stroke-[color:var(--color-blau-neue)]',
);

export const NavBarLink = classNames(
  'text-base leading-5 transition text-white-usc hover:text-white',
);

export const ActiveSideBarLink = classNames(
  'active-link border-l border-[color:var(--color-blau-neue)]',
);

export const mainBackgroundContainer = classNames(
  'absolute inset-0 mx-0 overflow-hidden -z-10 max-w-none max-lg:hidden',
);

export const mainBackgroundGradient = classNames(
  'absolute left-1/2 top-0 ml-[-38rem] h-[90rem] w-[260rem] [mask-image:linear-gradient(black,transparent)]',
);

export const gridPattern = classNames(
  'absolute inset-0 bg-gradient-to-r [mask-image:radial-gradient(farthest-side_at_top,white,transparent)] from-[#4189EF]/30 to-[#679eeb]/30 opacity-100',
);

export const getStartedButton = classNames(
  'inline-flex justify-center gap-1 px-4 py-1.5 overflow-hidden text-base font-bold text-[color:var(--color-blau-neue)] transition rounded-full bg-[color:var(--color-midnight-dark)] hover:bg-zinc-700bg-blue-400/10 text-blue-400 ring-1 ring-inset ring-blue-400/20 hover:bg-blue-400/10 hover:text-blue-300 hover:ring-blue-300',
);

export const mainPageH2 = classNames(
  'text-2xl font-normal no-underline group scroll-mt-24 text-[color:var(--color-blue-secondary)]',
);

export const documentationContainer = classNames(
  'pl-5 prose prose-invert documentation max-w-[50rem]',
);

export const notFoundNumber = classNames(
  'text-2xl font-semibold leading-8 text-[var(--color-blau-neue)] border-r border-[var(--color-divider)] inline-block pr-5',
);

export const notFoundHint = classNames(
  'mx-auto text-left text-[0.96rem] text-[var(--color-white-usc)] font-normal',
);

export const notFoundList = classNames(
  'mt-3 border-b border-[var(--color-white-usc)] divide-y divide-[var(--color-white-usc)]',
);

export const notFoundIConContainer = classNames(
  'flex items-center justify-center flex-none w-10 h-10 rounded-lg shadow-sm ring-1 ring-gray-900/10',
);

export const notFoundIcon = classNames('w-6 h-6 text-[var(--color-blau-neue)]');

export const notFoundCardTitle = classNames(
  'text-sm font-semibold leading-6 text-[var(--color-white-usc)]',
);

export const notFoundCardParagraph = classNames(
  'mt-2 text-sm leading-6 text-[var(--text-gray-secondary)]',
);
