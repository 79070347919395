import React from 'react';
import { Link } from 'react-router-dom';
import { GlobalT } from '../../assets/content/text/text.en';
import { mainPageSuggestions, suggestionsLink } from './Suggestions.styles';
import { RightArrowSVG } from '../../assets/svg/rightArrow';
import DocumentationRoutes from '../../routes';
import { splitAndCapitalise } from '../../helpers/splitAndCapitalise';

export const Suggestions = () => (
  <div data-testid="suggestions-container" className={mainPageSuggestions}>
    {DocumentationRoutes[0].content.slice(0, 4).map((route, id) => (
      <div key={id}>
        <h3 className="text-base font-semibold">{splitAndCapitalise(route.title)}</h3>
        <p className="mt-4">
          <Link className={suggestionsLink} to={`documentation/${route.title}`}>
            {GlobalT.CheckItOut}
            <RightArrowSVG />
          </Link>
        </p>
      </div>
    ))}
  </div>
);
