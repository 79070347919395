export const GlobalT = {
  'Login.Heading': 'Welcome Back',
  'Register.Heading': 'Create your developer account',
  WelcomeToUrbanSports: 'Urban Sports Connect API',
  WelcomeMainParagraph:
    'Schedule & Booking: Keep studio class schedules up-to-date between our systems, enabling instant bookings for Urban Sports Club members.',
  WelcomeParagraphSecondary:
    'Access Control: Give easy access to Urban Sports Club members with Access Control solutions.',
  GetStarted: 'Getting Started',
  MightLookFor: 'You might be looking for:',
  ReadAbout: 'More info on: ',
  CheckItOut: 'See more',
  NeedHelp: '',
  ContactUs: 'Contact us',
  NeedTechnicalSupport: 'Need technical support?',
  HaveQuestions: '',
  ClickToContact: 'Click here to contact us',
  NeedAssistance: '',
  UrbanSportsClub: 'Urban Sports Club',
  Fitogram: '',
  OneFit: '',
  pageNotExist: "This page doesn't exist!",
  mightLookFor: 'You might be looking for:',
  Home: 'Home',
};
