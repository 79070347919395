import React from 'react';
import CodeBlock from './CodeBlock';

interface Props {
  children: any;
}

const PreBlock = ({ children, ...rest }: Props) => {
  if ('type' in children && children['type'] === 'code') {
    return CodeBlock(children['props']);
  }
  return <pre {...rest}>{children}</pre>;
};

export default PreBlock;
