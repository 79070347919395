import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { ActiveSideBarLink } from '../../assets/styles/global';
import { sideBarChildLink, sideBarLink } from './Sidebar.styles';
import { RouteCorrector } from '../../helpers/routeCorrector';
import { setDisplayMode } from '../../features/app/appSlice';
import { splitAndCapitalise } from '../../helpers/splitAndCapitalise';

interface ListItemProps {
  address: string;
  title: string;
  hasChildren: boolean;
  childLinks?: string[];
}

export const ListItem = ({ address, title, hasChildren, childLinks }: ListItemProps) => {
  const { docId } = useParams();

  const dispatch = useAppDispatch();

  const displayMenu = () => {
    dispatch(setDisplayMode(false));
  };

  return (
    <>
      {!hasChildren ? (
        <li className="relative mb-3">
          <NavLink
            onClick={displayMenu}
            className={({ isActive }) =>
              isActive ? `${ActiveSideBarLink} ${sideBarLink}` : `${sideBarLink}`
            }
            to={address}
          >
            <span className="truncate">{splitAndCapitalise(title)}</span>
          </NavLink>
        </li>
      ) : (
        <li
          className={
            docId === title
              ? 'relative mb-3 rounded-md bg-[color:var(--color-midnight-light-menu)]'
              : 'relative mb-3'
          }
        >
          <NavLink
            onClick={displayMenu}
            aria-current="page"
            className={({ isActive }) =>
              isActive ? `${ActiveSideBarLink} ${sideBarLink}` : `${sideBarLink}`
            }
            to={address}
          >
            <span className="truncate">{splitAndCapitalise(title)}</span>
          </NavLink>
          <ul role="list" className={docId === title ? '' : 'hidden'}>
            {childLinks?.map((childLink, id) => (
              <li key={id} className="mb-1">
                <a
                  onClick={displayMenu}
                  className={`${sideBarChildLink}`}
                  href={`#${RouteCorrector(childLink)}`}
                >
                  <span className="truncate">{childLink}</span>
                </a>
              </li>
            ))}
          </ul>
        </li>
      )}
    </>
  );
};
