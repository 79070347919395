import React from 'react';

interface Props {
  children: any;
}

const BlockQuote = ({ children, ...rest }: Props) => (
  <div
    {...rest}
    className="my-6 flex items-center gap-2.5 rounded-xl border border-[var(--color-blau-neue)] bg-[var(--color-blue-blockquote)] p-4 leading-6 [--tw-prose-links-hover:1C61C4] [--tw-prose-links:white] font-bold"
  >
    <svg
      viewBox="0 0 16 16"
      aria-hidden="true"
      className="flex-none w-6 h-6 mr-2 fill-[var(--color-blau-neue)] stroke-white"
    >
      <circle cx="8" cy="8" r="8" strokeWidth="0" />
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.75 7.75h1.5v3.5"
      />
      <circle cx="8" cy="4" r=".5" fill="none" />
    </svg>
    <div className="[&amp;>:first-child]:mt-0 [&amp;>:last-child]:mb-0">
      <p>{children}</p>
    </div>
  </div>
);

export default BlockQuote;
