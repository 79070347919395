import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import PreBlock from '../../components/Code Block/PreBlock';
import { RouteCorrector } from '../../helpers/routeCorrector';
import NotFound from './404';
import { documentationContainer } from '../../assets/styles/global';
import BlockQuote from '../../components/BlockQuote/BlockQuote';
import Aside from '../../components/Aside/Aside';

const Documentation = () => {
  const { docId, docGroup } = useParams();

  const [content, setContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (docId && docGroup) {
      setLoading(true);
      import(`../../documentation/${docGroup}/${RouteCorrector(docId)}.md`)
        .then((res) => {
          fetch(res.default)
            .then((res) => res.text())
            .then((res) => {
              setContent(res);
              setLoading(false);
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
      window.scrollTo(0, 0);
    }
  }, [docId, docGroup]);

  useEffect(() => {
    const hash = window.location.hash;

    if (content && hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [content]);

  if (content) {
    return (
      <DashboardLayout>
        <div className={documentationContainer}>
          <Markdown
            options={{
              wrapper: 'article',
              overrides: {
                h1: {
                  props: {
                    className: 'scroll-mt-24 text-3xl text-[color:var(--color-blue-secondary)]',
                  },
                },
                h2: {
                  props: {
                    className: 'scroll-mt-24 text-2xl text-[color:var(--color-blue-secondary)]',
                  },
                },
                h3: {
                  props: {
                    className: 'scroll-mt-24 text-xl text-[color:var(--color-blau-neue)]',
                  },
                },
                h4: {
                  props: {
                    className: 'scroll-mt-24 text-lg text-[color:var(--color-blau-neue)]',
                  },
                },
                pre: PreBlock,
                blockquote: BlockQuote,
                aside: Aside,
              },
            }}
          >
            {content}
          </Markdown>
        </div>
      </DashboardLayout>
    );
  } else {
    return loading ? <DashboardLayout /> : <NotFound />;
  }
};

export default Documentation;
