import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import {
  navigationBar,
  navigationSpacing,
  navigationDimensions,
  navigationResponsive,
  navigationDivider,
} from './NavBar.styles';
import {
  normalBorderBottom,
  backgroundDark,
  backgroundDividerColor,
  SearchBar,
  borderDivider,
  SearchBarSpacing,
  SearchBarDimensions,
  SearchBarInput,
  SearchBarContainer,
  HamburgerMenuContainer,
  HamburgerMenu,
  NavBarLink,
  HamburgerMenuIcon,
} from '../../assets/styles/global';
import { MagnifierSVG } from '../../assets/svg/magnifier';
import DocumentationRoutes from '../../routes';
import { RouteCorrector } from '../../helpers/routeCorrector';
import { GlobalT } from '../../assets/content/text/text.en';
import { capitaliseFirstLetter } from '../../helpers/capitaliseFirstLetter';

interface NavBarProps {
  displayMode: boolean;
  changeDisplayMode: (toggle: boolean) => void;
}

export const NavBar = ({ changeDisplayMode, displayMode }: NavBarProps) => {
  const displayMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    changeDisplayMode(!displayMode);
  };

  return (
    <div
      className={
        displayMode
          ? 'hidden'
          : `${navigationBar} ${navigationSpacing} ${normalBorderBottom} ${backgroundDark} ${navigationDimensions} ${navigationResponsive} responsive-nav`
      }
    >
      <div className={`${navigationDivider} ${backgroundDividerColor}`} />
      <div className={SearchBarContainer}>
        <button
          type="button"
          className={`${SearchBar} ${borderDivider} ${SearchBarDimensions} ${SearchBarSpacing}`}
        >
          <MagnifierSVG />
          <input className={`${SearchBarInput}`} placeholder="Search here..." />
          <kbd className="ml-auto text-2xs text-zinc-400">
            <kbd className="font-sans">⌘</kbd>
            <kbd className="font-sans">K</kbd>
          </kbd>
        </button>
      </div>
      {/* Hamburger Menu */}
      <div className={displayMode ? 'hidden' : `${HamburgerMenuContainer}`}>
        <button
          onClick={displayMenu}
          type="button"
          className={HamburgerMenu}
          aria-label="Toggle navigation"
        >
          <svg
            viewBox="0 0 10 9"
            fill="none"
            strokeLinecap="round"
            aria-hidden="true"
            className={HamburgerMenuIcon}
          >
            <path d="M.5 1h9M.5 8h9M.5 4.5h9" />
          </svg>
        </button>
      </div>
      <div className="flex items-center gap-5">
        <nav className="hidden xsm-block">
          <ul role="list" className="flex items-center gap-8 navbarList">
            <li>
              <NavLink className={`${NavBarLink}`} to="/">
                {GlobalT.Home}
              </NavLink>
            </li>
            {DocumentationRoutes.map((group, id) => (
              <li key={id}>
                <NavLink
                  className={`${NavBarLink}`}
                  to={
                    group.content && group.content.length > 0
                      ? RouteCorrector(`/${group.title}/${group.content[0].title}`)
                      : '/'
                  }
                >
                  {capitaliseFirstLetter(group.title)}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};
